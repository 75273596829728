import React from 'react';
import { Link, StaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import p09pajaro01Audio from "../audio/p09pajaro01Audio.m4a"
import p09pajaro02Audio from "../audio/p09pajaro02Audio.m4a"
import p09PajarosVolandoAudio from "../audio/p09PajarosVolandoAudio.m4a"
import p09VO from "../audio/p09VO.m4a"
import Footer from "../components/Footer"

function importAll(r) {
    let images = {};
    r.keys().map((item, index) => { images[item.replace('./', '')] = r(item); });
    return images;
}
const images = importAll(require.context('../images/', false, /.jpg$|.png/));

class Page09 extends React.Component {
  constructor(props){
    super(props)
    this.state = { active: false,
                   cencerro: images["p08_cencerro.png"],
                 }
  }
  playAudio = (x) => {
    const audioEl = document.getElementsByClassName("audio-element")[x]
     audioEl.play()
}
handleCencerroClick = () => {
  this.setState({ active: !this.state.active }, () => {
      if(this.state.active){
        setTimeout( () => {this.setState({cencerro: images["resetBotonTrans.png"]})}, 1000 );
        setTimeout( () => {this.playAudio(2)}, 50)
      }
      else { this.setState({cencerro: images["p09_cencerro.png"]}) }
    })
}
  render() {
    return (
      <div className="main-container">
      <Link to="/Page08">
        <div className="page-turner">
          <img style={{transform: "scaleX(-1)", opacity: 0.75}} className="hw" src={images["arrowPages.png"]} alt="" />
        </div>
      </Link>
      <Link to="/Page10">
        <div className="page-turner right-page-pos">
        <img style={{opacity: 0.75}} className="hw" src={images["arrowPages.png"]} alt="" />
        </div>
      </Link>
        <div className="centered-container">
          <div className="backContainer004">
          <StaticQuery
            query={graphql`
              query {
                imageOne: file(relativePath: { eq: "p09_sombraPajaros.jpg" }) {
                  childImageSharp {
                    fluid(maxWidth: 1000) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
              }
            `}
      render={data => (
        <Img fluid={data.imageOne.childImageSharp.fluid} />
      )}
    />
          </div>
          <div className="p09Cuentamelo"  onClick={() => this.playAudio(3)}>
            <img className="hw" src={images["botonCuentamelo.png"]} alt="" />
          </div>
          <div className={"pajaroLgroup" + (this.state.active ? " active" : "")} >
            <div className="_idContainer139">
              <img className="_idGenObjectAttribute-1 _idGenObjectAttribute-2" src={images["p09_pajaroL.png"]} alt="" />
            </div>
            <div className={"_idContainer140" + (this.state.active ? " active" : "")} >
              <img className="_idGenObjectAttribute-1 _idGenObjectAttribute-2" src={images["p09_pajaroLalaL.png"]} alt="" />
            </div>
            <div className={"_idContainer141" + (this.state.active ? " active" : "")} >
              <img className="_idGenObjectAttribute-1 _idGenObjectAttribute-2" src={images["p09_pajaroLalaR.png"]} alt="" />
            </div>
          </div>
          <div className={"_idContainer145" + (this.state.active ? " active" : "")} >
            <div className={"_idContainer142" + (this.state.active ? " active" : "")} >
              <img className="_idGenObjectAttribute-1 _idGenObjectAttribute-2" src={images["p09_pararoRvolandoAlaR.png"]} alt="" />
            </div>
            <div className={"_idContainer143" + (this.state.active ? " active" : "")} >
              <img className="_idGenObjectAttribute-1 _idGenObjectAttribute-2" src={images["p09_pajaroRvolandoAlaL.png"]} alt="" />
            </div>
            <div className={"_idContainer144" + (this.state.active ? " active" : "")} >
              <img className="_idGenObjectAttribute-1 _idGenObjectAttribute-2" src={images["p09_pajaroRvolando.png"]} alt="" />
            </div>
          </div>
          <div className={"_idContainer149" + (this.state.active ? " active" : "")} >
            <div className="_idContainer146">
              <img className="_idGenObjectAttribute-1 _idGenObjectAttribute-2" src={images["p09_pajaroLvolandoAlaR.png"]} alt="" />
            </div>
            <div className="_idContainer147">
              <img className="_idGenObjectAttribute-1 _idGenObjectAttribute-2" src={images["p09_pararoLvolandoAlaL.png"]} alt="" />
            </div>
            <div className="_idContainer148">
              <img className="_idGenObjectAttribute-1 _idGenObjectAttribute-2" src={images["p09_pajaroLvolando.png"]} alt="" />
            </div>
          </div>
          <div className={"pajaroRgroup" + (this.state.active ? " active" : "")} >
            <div className="_idContainer150">
              <img className="_idGenObjectAttribute-1 _idGenObjectAttribute-2" src={images["p09_pajaroR.png"]} alt="" />
            </div>
            <div className="_idContainer151">
              <img className="_idGenObjectAttribute-1 _idGenObjectAttribute-2" src={images["p09_pajaroRAla.png"]} alt="" />
            </div>
          </div>
          <div className="_idContainer152 Basic-Text-Frame">
            <span>Dos pájaros volaban de un tirón.</span>
          </div>
          <div className="_idContainer153">
            <img className="_idGenObjectAttribute-1 _idGenObjectAttribute-2" src={images["4262.png"]} onClick={() => this.playAudio(0)} alt="" />
          </div>
          <div className="_idContainer154">
            <img className="_idGenObjectAttribute-1 _idGenObjectAttribute-2" src={images["4263.png"]} onClick={() => this.playAudio(1)} alt="" />
          </div>
          <div className={"_idContainer155" + (this.state.active ? " active" : "")} onClick={()=> this.handleCencerroClick()}>
            <img className="cencerroReset _idGenObjectAttribute-1 _idGenObjectAttribute-2" src={this.state.cencerro} alt="" />
          </div>
          <audio className="audio-element">
            <source src={p09pajaro01Audio}></source>
          </audio>
          <audio className="audio-element">
            <source src={p09pajaro02Audio}></source>
          </audio>
          <audio className="audio-element">
            <source src={p09PajarosVolandoAudio}></source>
          </audio>
          <audio className="audio-element">
            <source src={p09VO}></source>
          </audio>
        </div>
      <Footer />
      </div>
    )
  }
  }

export default Page09;
